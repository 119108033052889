// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesBetRow.css'
import { NAVIGATION_ROUTES } from '../../../../../utils/NavigationUtils';
import { BET_TYPES, BET_STATUS } from '../../../../../utils/Constants';

// component imports

// asset imports
import EditIcon from '../../../../../assets/icons/ic_edit_green.png'
import CashIcon from '../../../../../assets/icons/ic_cash.png'
import CoinIcon from '../../../../../assets/icons/ic_coin.png'

const BetRow = ({
  is_header_row, 
  bet_data,
  onEditBetPressed
}) => {
  // non-state variables

  // state variables
  const [betType, setBetType] = useState("")
  const [betWinStatus, setBetWinStatus] = useState("")
  const [odds, setOdds] = useState("")
  const [potentialPayout, setPotentialPayout] = useState(0)
  const [selectionInfo, setSelectionInfo] = useState("")
  
  // use effects

  useEffect(() => {
    if (bet_data?.bet_type === "1") {
      setBetType(BET_TYPES?.STRAIGHT)
    } 
    else if(bet_data?.bet_type === "2") {
      setBetType(BET_TYPES?.P2P)
    }
    else if ((bet_data?.bet_type === "5" && bet_data?.wager_amount === "0.00") 
      || bet_data?.is_multibet) {
      setBetType(BET_TYPES?.GROUP)
    }
    else {
      setBetType(BET_TYPES?.PARLAY)
    }
  }, [bet_data])

  useEffect(() => {
    if (betType === BET_TYPES?.PARLAY) {
      setOdds(convertDecimalToAmerican(bet_data?.combined_odds))
    } else {
      const betOdds = bet_data?.selection?.market?.outcome?.find(outcome => outcome?.chosen === '1')?.odds
      setOdds(convertDecimalToAmerican(betOdds))
    }
  }, [betType])

  useEffect(() => {
    if (betType === BET_TYPES?.PARLAY) {

      const combinedOdds = bet_data?.combined_odds
      const wagerAmount = bet_data?.wager_amount
      const payoutAmount = parseFloat(combinedOdds) * parseFloat(wagerAmount)

      setPotentialPayout(payoutAmount?.toFixed(2))

    } else {

      const betOdds = bet_data?.selection?.market?.outcome?.find(outcome => outcome?.chosen === '1')?.odds
      const wagerAmount = bet_data?.wager_amount
      const payoutAmount = parseFloat(betOdds) * parseFloat(wagerAmount)

      setPotentialPayout(payoutAmount?.toFixed(2))

    }
  }, [bet_data])

  useEffect(() => {
    if (betType === BET_TYPES?.PARLAY) {

      setSelectionInfo("")
      setSelectionInfo(JSON.stringify(bet_data?.bets))

    } else if (betType === BET_TYPES?.STRAIGHT) {

      setSelectionInfo(JSON.stringify(bet_data?.selection))

    }
  }, [bet_data])

  useEffect(() => {

    if (bet_data?.cancelled) {
      setBetWinStatus(BET_STATUS?.CANCELLED)
    }
    else if (bet_data?.void_factor === "1") {
      setBetWinStatus(BET_STATUS?.PUSHED)
    }
    else if (bet_data?.won === "-1") {
      setBetWinStatus(BET_STATUS?.UNSETTLED)
    }
    else if (bet_data?.won === "0") {
      setBetWinStatus(BET_STATUS?.LOST)
    }
    else {
      setBetWinStatus(BET_STATUS?.WON)
    }

  }, [bet_data])

  // api functions

  // helper functions
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(selectionInfo)
      .then()
      .catch();
  };

  const convertDecimalToAmerican = (decimalOdds) => {
    let americanOdds;

    if (decimalOdds > 2.0) {
        americanOdds = '+' + Math.round((decimalOdds - 1) * 100);
    } else if (decimalOdds < 2.0) {
        americanOdds = Math.round(-(100 / (1 - decimalOdds)));
        americanOdds =
            americanOdds > 0 ? '-' + americanOdds : americanOdds.toString();
    } else {
        americanOdds = '+100';
    }

    return americanOdds;
}

  const handleEditBetPressed = () => {
    onEditBetPressed(bet_data)
  }

  const formatTimestamp = (timestampStr) => {
    const timestamp = parseFloat(timestampStr.split('.')[0]);
  
    const date = new Date(timestamp * 1000);
  
    const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return 'th'; // Special case for teens
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    }
  
    // Formatting the date
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate();
    const ordinalSuffix = getOrdinalSuffix(day);
  
    // Return the formatted string
    return `${formattedHours}:${formattedMinutes} ${ampm}, ${month} ${day}${ordinalSuffix}`;
  }


  return (
    <div className={`table-row ${is_header_row ? 'table-header-row' : ''}`}>

      <div className='table-cell bet-cell'>
        <p className={is_header_row ? 'transaction-cell-label' : 'bet-id-cell-label'}>
          {
            is_header_row 
            ? "Date / Time" 
            : formatTimestamp(bet_data?.created_at)
          }
        </p>
      </div>

      <div className='table-cell bet-cell'>
        <p className={is_header_row ? 'transaction-cell-label' : 'bet-id-cell-label'}>
          {
            is_header_row ?
            "ID" :
            bet_data?.bet_id
          }
        </p>
      </div>

      <div className='table-cell bet-cell'>
      {
        is_header_row 
        ? (
          
            <p className='transaction-cell-label'>
              Money Type
            </p>
         
        )
        : (
            <div className="bet-cell-label">
              {bet_data?.money_type}
            </div>
        )
      }
      </div>

      <div className='table-cell bet-cell'>
        <p className='bet-cell-label'>
          {
            is_header_row ?
            "Wager" :
            (bet_data?.money_type === "rebet_cash" ? parseFloat(bet_data?.wager_amount)?.toFixed(2) : parseFloat(bet_data?.wager_amount)?.toFixed(0))
          }
        </p>
      </div>

      <div className='table-cell bet-cell'>

          <div className='bet-cell-label'>
            {
              is_header_row 
              ? "Payout" 
              : bet_data?.payout
              ? (bet_data?.money_type === "rebet_cash" ? parseFloat(bet_data?.payout)?.toFixed(2) : parseFloat(bet_data?.payout)?.toFixed(0))
              : betWinStatus === BET_STATUS?.LOST
              ? "0"
              : betWinStatus === BET_STATUS?.CANCELLED
              ?  (bet_data?.money_type === "rebet_cash" ? parseFloat(bet_data?.wager_amount)?.toFixed(2) : parseFloat(bet_data?.wager_amount)?.toFixed(0))
              : "-"
            }
          </div>

      </div>

      {/* include check for void factor */}
      <div className='table-cell bet-cell'>
        <div className={
            is_header_row 
            ? ""
            : (
              betWinStatus === BET_STATUS?.WON
            ? "win-type-text-background-good"
            : betWinStatus === BET_STATUS?.PUSHED || betWinStatus === BET_STATUS?.CANCELLED
            ? "win-type-text-background-neutral"
            : betWinStatus === BET_STATUS?.LOST
            ? "win-type-text-background-negative"
            : betWinStatus === BET_STATUS?.UNSETTLED
            ? "value-text-background-unsettled"
            : ""
            )
            }
          >
            <div className={
            is_header_row 
            ? "bet-cell-label"
            : (
              betWinStatus === BET_STATUS?.WON
            ? "win-type-text-good"
            : betWinStatus === BET_STATUS?.PUSHED || betWinStatus === BET_STATUS?.CANCELLED
            ? "win-type-text-neutral"
            : betWinStatus === BET_STATUS?.LOST
            ? "win-type-text-negative"
            : betWinStatus === BET_STATUS?.UNSETTLED
            ? "value-text-unsettled"
            : "bet-cell-label"
            )
            }
          >
            {
              is_header_row 
              ? "Status" 
              : betWinStatus

            }
            </div>
        </div>
      </div>

      <div className='table-cell bet-cell'>
        <p className='bet-cell-label'>
          {
            is_header_row ?
            "Type" :
            betType
          }
        </p>
      </div>

      <div className='table-cell bet-cell'>
        <p className='bet-cell-label'>
          {/* {
            is_header_row 
            ? "Odds Provider" 
            : bet_data?.odds_provider 
            ? bet_data?.odds_provider
            : "sportradar"
          } */}
          {
            is_header_row 
            ? "Odds" 
            : odds
          }
        </p>
      </div>

      <div className='table-cell bet-cell'>
        {is_header_row ? (
          <p className='bet-cell-label'>
            Edit
          </p>
        ) : (
          <div className='actions-container'>

            <button
              className={`edit-user-button ${bet_data.finalized === "0" ? '' : 'disabled'}`}
              onClick={handleEditBetPressed}
              disabled={bet_data.finalized === "0" ? false : true }
            >
              <img 
                className='button-icon'
                src={EditIcon}
                alt='edit icon'
              />
            </button>

          </div>
        )
      }
      </div>

      <div className='table-cell bet-cell'>
        <p className='bet-cell-label'>
          {/* {
            is_header_row 
            ? "Odds Provider" 
            : bet_data?.odds_provider 
            ? bet_data?.odds_provider
            : "sportradar"
          } */}
          {
            is_header_row 
            ? "Potential Payout" 
            : potentialPayout
          }
        </p>
      </div>

      <div className='table-cell bet-cell'>
        <button 
        className='bet-cell-label'
        onClick={copyToClipboard}
        >
          {/* {
            is_header_row 
            ? "Odds Provider" 
            : bet_data?.odds_provider 
            ? bet_data?.odds_provider
            : "sportradar"
          } */}
          {
            is_header_row 
            ? "Selection" 
            : selectionInfo
          }
        </button>
      </div>

    </div>
  );
};

export default BetRow;

