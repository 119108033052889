// third party imports
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesUserTable.css'
import createPrivateAdminPanelInstance from '../../../../api/PrivateAdminPanelInstance'
import { handleExpiredTokenError } from '../../../../utils/ErrorUtils';

// component imports
import DropDown from '../../../CommonComponents/DropDown/DropDown';
import UserRow from './UserRow/UserRow';
import DeleteUserPopup from '../../UserManager/UserActions/ActionPopups/DeleteUserPopup/DeleteUserPopup';
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader';

// asset imports
import SearchIcon from '../../../../assets/icons/ic_search.png'
import { API_ROUTES } from '../../../../api/ApiRoutes';
import DeleteUser from '../../DeleteUser/DeleteUser';

const UserTable = () => {
  // non-state variables
  const navigate = useNavigate()
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  const SEARCH_OPTIONS = {
    EMAIL: "email", 
    USERNAME: "username", 
    USERID: "user_id"
   }

  // state variables
  const [searchValue, setSearchValue] = useState('')
  const [searchBy, setSearchBy] = useState('')
  const [searchButtonActive, setSearchButtonActive] = useState(false)
  const [isDeleteUserPopupVisible, setIsDeleteUserPopupVisible] = useState(false)
  const [allUsers, setAllUsers] = useState([])
  const [usersToShowInTable, setUsersToShowInTable] = useState([])
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState({})
  const [isLoadingUsers, setIsLoadingUsers] = useState(false)
  const [userToDelete, setUserToDelete] = useState({}) 
  
  // use effects'
  useEffect(() => {
    getPageOfUsers()
  }, [])

  useEffect(() => {
    if (searchValue && searchBy) {
      setSearchButtonActive(true)
    } else {
      setSearchButtonActive(false)
    }
  }, [searchValue, searchBy])

  // api functions
  const getPageOfUsers = async () => {
    setIsLoadingUsers(true)
    const params = {
      last_evaluated_key: lastEvaluatedKey
    }

    try {

      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_USERS, params)

      setAllUsers([response?.data?.data?.items, ...allUsers])
      setLastEvaluatedKey(response?.data?.data?.last_evaluated_key)

      const first8Users = response?.data?.data?.items?.splice(0, 8)
      setUsersToShowInTable(first8Users)

      setIsLoadingUsers(false)

    } catch (error) {
      console.log("Error fetching users", error)
      setIsLoadingUsers(false)
      handleExpiredTokenError(navigate, error?.response)
    }
  }

  const searchUser = async (user_id) => {
    setIsLoadingUsers(true)
    const params = {
      search_by: searchBy, 
      search_str: user_id ? user_id : searchValue
    }

    try {
      
      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.SEARCH_USER, params)

      setUsersToShowInTable([response?.data?.data])

      setIsLoadingUsers(false)

    } catch (error) {
      console.log("Error searching for user", error?.response)
      setUsersToShowInTable([])
      setIsLoadingUsers(false)
      handleExpiredTokenError(navigate, error?.response)
    }
  }

  // helper functions
  const handleSearchValueChange = (event) => {
    setSearchValue(event?.target?.value)
  }

  const handleChangeSearchBy = (value) => {
    setSearchBy(value)
  }

  const handleSearchPressed = () => {
    searchUser()
  }

  const handleNextPressed = () => {
    getPageOfUsers()
  }

  const handlePrevPressed = () => {
    getPageOfUsers()
  }

  const handleDeleteUserPressed = (user_id) => {
    setUserToDelete(user_id)
    setIsDeleteUserPopupVisible(true)
  }

  const handleRefreshUserData = () => {
    searchUser(userToDelete?.user_id)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && searchButtonActive ) {
      // Run the function you want when the user presses Enter
      console.log("Enter key pressed!");
      // Call the search function or any other logic
      handleSearchPressed();
    }
  };

  return (
    
    <div className="user-table">
      
      <>
      <div className='head-controls'>

        <div className='header-text-container'>
            <p className='header-text'>
              Rebet Users
            </p>
        </div>

        <input
            type="text"
            placeholder="Search users..."
            className="users-searchInput"
            value={searchValue}
            onChange={handleSearchValueChange}
            onKeyDown={handleKeyDown}
          />

        <div className='drop-down-container'>
          <DropDown 
          drop_down_options={SEARCH_OPTIONS}
          selected_value={searchBy}
          set_selected_value={handleChangeSearchBy}
          />
        </div>

        <button
          className={ searchButtonActive ? 'search-button' : 'search-button-inactive'}
          onClick={handleSearchPressed}
        >
          <img 
            className='search-icon'
            alt='search-icon'
            src={SearchIcon}
          />
        </button>

      </div>

      <div className='table-container'>

        {
          isLoadingUsers
          ? (
            <RebetLoader
              height={'100px'}
              width={'100px'} />
          )
          : (
            <>

              <UserRow 
              is_header_row={true}
              user_data={{}}
              onDeleteUserPressed={handleDeleteUserPressed}
              />

              {usersToShowInTable && usersToShowInTable.map((user, index) => (
                <UserRow
                  key={user.user_id}
                  is_header_row={false}
                  user_data={user}
                  onDeleteUserPressed={handleDeleteUserPressed}
                />
              ))}

              

            </>
          )
        }
        
      
      </div>
      
      {!isLoadingUsers && 
        <div className='table-controls-container'>
            <DeleteUser />
            <p 
            className='prev-text'
            onClick={handlePrevPressed}
            >
              Prev
            </p>
            <p 
            className='next-text'
            onClick={handleNextPressed}
            >
              Next
            </p>
        </div>
      }
      <DeleteUserPopup 
            isVisible={isDeleteUserPopupVisible}
            onClosePressed={() => setIsDeleteUserPopupVisible(false)}
            userData={userToDelete}
            refreshUserData={handleRefreshUserData}
            />
      </>

    </div>
  );
};

export default UserTable;

