import React, { useEffect, useState } from "react";
import Layout from "../CommonComponents/Layout/Layout";
import { SIDER_TABS } from "../../utils/Constants";

import "./stylesPromotions.css";
import createPrivateAdminPanelInstance from "../../api/PrivateAdminPanelInstance";
import { API_ROUTES } from "../../api/ApiRoutes";
import RebetLoader from "../CommonComponents/RebetLoader/RebetLoader";

const Promotions = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(
    token?.IdToken
  );

  const [initialDeposit, setInitialDeposit] = useState({
    max_free_play_cash: "",
    referrer_bonus_amount: "",
  });
  const [isApiRunning, setIsApiRunning] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const fetchInitialDepositAndReferrerValues = async () => {
    setIsApiRunning(true);

    try {
      const response = await PrivateAdminPanelInstance?.post(
        API_ROUTES?.FETCH_INITIAL_DEPOSIT_AND_REFERRER_VALUES
      );
      const data = response?.data?.data || {};

      setInitialDeposit({
        max_free_play_cash: data?.max_free_play_cash || "",
        referrer_bonus_amount: data?.referrer_bonus_amount || "",
      });
    } catch (err) {
      console.log("Error fetching values", err);
      setError("Failed to fetch values. Please try again.");
    } finally {
      setIsApiRunning(false);
    }
  };

  const updateInitialDepositAndReferrerValues = async () => {
    const params = {
      max_free_play_cash: initialDeposit?.max_free_play_cash,
      referrer_bonus_amount: initialDeposit?.referrer_bonus_amount,
    };

    setIsApiRunning(true);
    setError("");

    try {
      await PrivateAdminPanelInstance?.post(
        API_ROUTES?.UPDATE_INITIAL_DEPOSIT_AND_REFERRER_VALUES,
        params
      );
      await fetchInitialDepositAndReferrerValues(); 
      setSuccessMessage("Values updated successfully.");

      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    } catch (err) {
      console.log("Error updating values", err);
      setError("Failed to update values. Please try again.");
    } finally {
      setIsApiRunning(false);
    }
  };

  useEffect(() => {
    fetchInitialDepositAndReferrerValues();
  }, []);

  return (
    <Layout selectedTab={SIDER_TABS?.PROMOTIONS}>
      <div className="promotions-container">
        <div className="bonuses-container">
          <h2>Bonuses</h2>
          {error && <p className="error-message">{error}</p>}
          {successMessage && <p className="success-message">{successMessage}</p>}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              updateInitialDepositAndReferrerValues();
            }}
          >
            <div className="form-group">
              <label htmlFor="initialDeposit">Max Free Play Cash</label>
              <input
                id="initialDeposit"
                value={initialDeposit.max_free_play_cash}
                onChange={(e) =>
                  setInitialDeposit((prev) => ({
                    ...prev,
                    max_free_play_cash: e.target.value,
                  }))
                }
                disabled={isApiRunning}
              />
            </div>
            <div className="form-group">
              <label htmlFor="referrer">Referrer Bonus Amount</label>
              <input
                id="referrer"
                type="text"
                value={initialDeposit.referrer_bonus_amount}
                onChange={(e) =>
                  setInitialDeposit((prev) => ({
                    ...prev,
                    referrer_bonus_amount: e.target.value,
                  }))
                }
                disabled={isApiRunning}
              />
            </div>
            <button 
            type="submit"
            className={`${isApiRunning ? 'button-sending' : ''}`}
            disabled={isApiRunning}>
              {isApiRunning ? (
                <RebetLoader height={"50px"} width={"50px"} />
              ) : (
                "Update Values"
              )}
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Promotions;
