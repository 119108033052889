export const PROFILE_IMAGE_BASE_URL = 'https://api.rebet.app/admin_panel/v2/admin'

export const ADMIN_PANEL_BACKEND_BASE_URL = 'https://api.rebet.app/admin_panel/v2/admin'
export const AFFILIATE_PANEL_BACKEND_INSTANCE = 'https://559xyery3g.execute-api.us-east-2.amazonaws.com/dev/admin-management-v3'

export const ONBOARDING_PAGE_STATE = {
    LOGIN: 'login',
    SIGNUP: 'signup'
}

export const SIDER_TABS = {
    USERS: "Users",
    SPORTSBOOK: "Sportsbook",
    KPIS: "KPIs",
    AFFILIATE: "Affiliates",
    ADMIN: "Admin",
    EVENTS: "Events",
    STAFF_MANAGEMENT: "Staff Management",
    PAYMENTS: "Payments",
    PROMOTIONS: "Promotions"
}

export const USER_HEADER_TABS = {
    OVERVIEW: "overview",
    TRANSACTIONS: "transactions",
    BETS: "bets",
    ALT_ODS: "alt-ods",
}

export const SPORTSBOOK_HEADER_TABS = {
    LEAGUES: "Leagues"
}

export const KPI_HEADER_TABS = {
    USERS: "Users",
    BETS: "Bets",
    FINANCIALS: "Financials",
    TRANSACTIONS: "Transactions",
    STATES: "States"
}

export const AFFILIATE_HEADER_TABS = {
    AFFILIATE_KPIS: "Affiliate KPIs",
    FINANCIALS: "Financials",
    INDIVIDUAL_STATS: "Individual Stats"
}

export const TIME_FRAMES = {
    MONTH: "month",
    DAY: "day"
}

export const NUM_WEEKS = {
    ONE: "1 week",
    TWO: "2 weeks",
    THREE: "3 weeks ",
    FOUR: "4 weeks",
    MONTHLY: "Monthly"
}

export const DATE_RANGE = {
    LAST_7_DAYS: "Last 7 days",
    LAST_21_DAYS: "Last 21 days",
    LAST_30_DAYS: "Last 30 days",
    LAST_45_DAYS: "Last 45 days"
}

export const BET_TYPES = {
    STRAIGHT: "straight",
    GROUP: "group",
    PARLAY: "parlay",
    P2P: "p2p"
}

export const BET_STATUS = {
    WON: "won",
    LOST: "lost",
    PUSHED: "pushed",
    UNSETTLED: "unsettled",
    CANCELLED: "cancelled"
}

export const ADMIN_TYPES = {
    SUPER_ADMIN: "super-admin-v2",
    CUSTOMER_SUPPORT: "customer-support"
}

export const PERSONAL_LIMITS = {
    TIME_LIMITS: 'time limits',
    CURRENCY_LIMITS: 'currency limits',
    WAGGER_LIMITS: 'wagger limits',
    LOSS_LIMITS: 'loss limits',
    CASINO_LIMITS: 'casino limits'
}

export const FREE_BET_TYPES = {
    WAGERED: "wagered",
    PAIDOUT: "paidout",
}