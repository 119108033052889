// third party imports
import React, { useState } from "react";

// internal rebet imports
import "./stylesDeleteUser.css";

// component imports
import { SIDER_TABS } from "../../../utils/Constants";
import { Layout } from "react-feather";
import DeleteUserPopup from "./DeleteUserPopup/DeleteUserPopup";

// asset imports

const DeleteUser = () => {
  // non-state variables

  // state variables
  const [isAddNewEventsPopupVisible, setIsAddNewEventsPopupVisible] =
    useState(false);

  // use effects

  // api functions

  // helper functions

  return (
    <div className="delete-user">
      <DeleteUserPopup
        isVisible={isAddNewEventsPopupVisible}
        onClosePressed={setIsAddNewEventsPopupVisible}
      />
      <button
        className="delete-user-button"
        onClick={() => setIsAddNewEventsPopupVisible(true)}
      >
        Delete User
      </button>
    </div>
  );
};

export default DeleteUser;
