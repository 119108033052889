import React, { useState } from "react";

import "./stylesDeleteUserPopup.css";
import { X } from "react-feather";
import createPrivateAdminPanelInstance from "../../../../api/PrivateAdminPanelInstance";
import { API_ROUTES } from "../../../../api/ApiRoutes";

function DeleteUserPopup({ isVisible, onClosePressed }) {
  const token = JSON.parse(localStorage.getItem("token"));

  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(
    token?.IdToken
  );

  const [username, setUsername] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const buttonDisabled = username.length <= 0 ? true : false;

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrorMessage("");
    setIsSubmitting(true);

    try {
      const params = { username: username };

      await PrivateAdminPanelInstance?.post(API_ROUTES?.DELETE_USER, params);

      setSuccessMessage("User deleted successfully!");

      setTimeout(() => {
        setSuccessMessage("");
        setUsername("");
      }, 3000);
    } catch (error) {
      setErrorMessage("Error deleting user. Please try again.");
      console.log("Error deleting user:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClosePressed = () => {
    onClosePressed();
    setUsername("");
    setIsSubmitting(false);
    setSuccessMessage("");
    setErrorMessage("");
  };

  if (!isVisible) return null;

  return (
    <div className="delete-user-popup">
      <div className="delete-user-panel">
        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <div className="delete-user-panel-header">
          <p className="title">Delete User</p>
          <button className="close-button" onClick={handleClosePressed}>
            <X className="close-icon" />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              value={username}
              placeholder="Enter username"
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            disabled={buttonDisabled}
            className={`submit-button ${
              buttonDisabled || isSubmitting ? "disabled" : null
            }`}
          >
            {isSubmitting ? "Deleting User..." : "Delete User"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default DeleteUserPopup;
