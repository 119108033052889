// third party imports
import React, { useEffect, useState } from "react";
import { X } from "react-feather";

// internal rebet imports
import "./stylesAvailablePromotionsPopup.css";

// component imports
import RebetLoader from "../../../../../CommonComponents/RebetLoader/RebetLoader";
import createPrivateAdminPanelInstance from "../../../../../../api/PrivateAdminPanelInstance";
import { API_ROUTES } from "../../../../../../api/ApiRoutes";
import { formatDistanceToNow, set } from "date-fns";

// asset imports

import DeleteIcon from "../../../../../../assets/icons/ic_delete_red.png";

const AvailablePromotionsPopup = ({ isVisible, onClosePressed, userId }) => {
  // non-state variables
  const token = JSON.parse(localStorage.getItem("token"));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(
    token?.IdToken
  );

  // state variables
  const [isApiRunning, setIsApiRunning] = useState(false);
  const [availablePromotions, setAvailablePromotions] = useState([]);
  const [confirmationValue, setConfirmationValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const getAvailablePromotions = async () => {
    if (!userId) {
      return;
    }

    setIsApiRunning(true);
    const params = {
      user_id: userId,
    };
    try {
      const response = await PrivateAdminPanelInstance.post(
        API_ROUTES.GET_USER_ACTIVE_BONUSES,
        params
      );
      const data = response.data?.data;

      setAvailablePromotions(data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsApiRunning(false);
    }
  };

  useEffect(() => {
    getAvailablePromotions();
  }, [userId]);

  const handleClosePressed = () => {
    onClosePressed();
    setConfirmationValue("");
    setDeleteId("");
  };

  const dateTransform = (timestamp) => {
    let date = new Date(timestamp * 1000);
    const result = formatDistanceToNow(date, {
      addSuffix: true,
    });
    return result;
  };

  const deletePromotion = async (promotionId) => {
    setIsApiRunning(true);
    setErrorMessage("");

    const params = {
      bonus_id: promotionId,
      user_id: userId,
    };

    try {
      await PrivateAdminPanelInstance.post(
        API_ROUTES.EXPIRE_USER_PROMOTION,
        params
      );

      await getAvailablePromotions();
      setSuccessMessage("Promotion successfully deleted.");
      setDeleteId("");
      setConfirmationValue("");

      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    } catch (error) {
      setErrorMessage("Error deleting promotion.");
      console.log("error", error);
    } finally {
      setIsApiRunning(false);
    }
  };

  const handleKeyDown = (event, promotionId) => {
    if (event.key === "Enter") {
      if (confirmationValue === "delete promotion") {
        deletePromotion(promotionId);
      }
    }
  };

  const handleConfirmationValueChange = (event) => {
    setConfirmationValue(event?.target?.value);
  };

  const handleSetDeleteId = (promotionId) => {
    setConfirmationValue("");
    if (deleteId === promotionId) {
      setDeleteId("");
      return;
    }
    setDeleteId(promotionId);
  };

  if (!isVisible) return null;

  return (
    <div className="suspend-user-popup promotion-popup">
      <div className="suspend-user-panel">
        {isApiRunning ? (
          <RebetLoader width={100} height={100} />
        ) : (
          <>
            <div className="success-message">{successMessage}</div>
            <div className="error-message">{errorMessage}</div>
            <div className="suspend-user-header">
              <div className="suspend-user-header-label">
                Available Promotions
              </div>
              <button className="close-button" onClick={handleClosePressed}>
                <X className="close-icon" />
              </button>
            </div>
            <div className="promotion-container">
              {availablePromotions.map((promotion, index) => {
                return (
                  <div className="promotion" key={promotion.bonus_id}>
                    <div className="delete-container">
                      <img
                        className="delete-icon"
                        src={DeleteIcon}
                        alt="delete icon"
                        onClick={() => handleSetDeleteId(promotion.bonus_id)}
                      />
                    </div>
                    <p className=" promotion-expire-time">
                      {dateTransform(promotion.time_expired_in)}
                    </p>
                    <h3 className="promotion-title">
                      {promotion.banner_title}
                    </h3>
                    <p className=" promotion-description">
                      {promotion.braze_banner_msg}
                    </p>
                    {promotion.bonus_id === deleteId && (
                      <div className="confirmation-container">
                        <span>
                          To confirm, type "delete promotion" in the box below{" "}
                          <br /> and press ENTER
                        </span>
                        <input
                          type="text"
                          placeholder=""
                          className="users-searchInput"
                          value={confirmationValue}
                          onChange={handleConfirmationValueChange}
                          onKeyDown={(e) =>
                            handleKeyDown(e, promotion.bonus_id)
                          }
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AvailablePromotionsPopup;
