// third party imports
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesUserManager.css'
import { NAVIGATION_ROUTES } from '../../../utils/NavigationUtils';
import { USER_HEADER_TABS } from '../../../utils/Constants';
import { SIDER_TABS } from '../../../utils/Constants';
import { fetchSpecificUsersData } from '../../../api/CommonFunctions/CommonFunctions';
import createPrivateAdminPanelInstance from '../../../api/PrivateAdminPanelInstance';

// component imports
import Sider from '../../CommonComponents/Sider/Sider';
import Header from '../../CommonComponents/Header/Header';
import UserManagerHeader from './UserManagerHeader/UserManagerHeader';
import UserActions from './UserActions/UserActions';
import UserData from './UserData/UserData';
import UserStatuses from './UserStatuses/UserStatuses';
import EditWalletPopup from './UserActions/ActionPopups/EditWalletPopup/EditWalletPopup';
import SuspendUserPopUp from './UserActions/ActionPopups/SuspendUserPopup/SuspendUserPopup';
import ToggleACHPopup from './UserActions/ActionPopups/ToggleAchPopup/ToggleACHPopup';
import EditUserDataPopup from './UserActions/ActionPopups/EditUserDataPopup/EditUserDataPopup';
import DeleteUserPopup from './UserActions/ActionPopups/DeleteUserPopup/DeleteUserPopup';
import PaymentInstrumentsPopup from './UserActions/ActionPopups/PaymentInstrumentsPopup/PaymentInstrumentsPopup';
import BetTable from './BetsTable/BetTable';
import TransactionTable from './TransactionsTable/TransactionTable';

// asset imports
import { ArrowLeft } from 'react-feather';
import DepositAttempts from '../../KPIs/Transactions/DepositAttempts/DepositAttempts';
import PersonalLimitsPopup from './UserActions/ActionPopups/PersonalLimitsPopup/PersonalLimitsPopup';
import Layout from '../../CommonComponents/Layout/Layout';
import FreeBetsPopup from './UserActions/ActionPopups/FreeBetsPopup/FreeBetsPopup';
import ProfitBoostPopup from './UserActions/ActionPopups/ProfitBoostPopup/ProfitBoostPopup';
import AltOddsTable from './AltOddsTable/AltOddsTable';
import AvailablePromotionsPopup from './UserActions/ActionPopups/AvailablePromotionsPopup/AvailablePromotionsPopup';


const UserManager = () => {
  // non-state variables
  const navigate = useNavigate();
  const location = useLocation();
  const {
    user_data,
    wallet_active
  } = location.state || {};
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  // state variables
  const [selectedTab, setSelectedTab] = useState(USER_HEADER_TABS?.OVERVIEW)
  const [isEditWalletPopupVisible, setIsEditWalletPopupVisible] = useState(false)
  const [isSuspendUserPopupVisible, setIsSuspendUserPopupVisible] = useState(false)
  const [isToggleACHPopupVisible, setIsToggleACHPopupVisible] = useState(false)
  const [isDeleteUserPopupVisible, setIsDeleteUserPopupVisible] = useState(false)
  const [isEditUserDataPopupVisible, setIsEditUserDataPopupVisible] = useState(false)
  const [isPersonalLimitsPopupVisible, setIsPersonalLimitsPopupVisible] = useState(false)
  const [isAvailablePromotionsPopupVisible, setIsAvailablePromotionsPopupVisible] = useState(false)
  const [isFreeBetPopupVisible, setIsFreeBetPopupVisible] = useState(false)
  const [isProfitBoostPopupVisible, setIsProfitBoostPopupVisible] = useState(false)
  const [isPaymentInstrumentsPopupVisible, setIsPaymentInstrumentsPopupVisible] = useState(false)
  const [upToDateUserData, setUpToDateUserData] = useState(false)

  // use effects
  useEffect(() => {
    setUpToDateUserData(user_data)
  }, [user_data])

  useEffect(() => {
    if (wallet_active) {
      setIsEditWalletPopupVisible(true)
    }
  }, [wallet_active])

  // api functions
  const refreshUserData = async () => {
    const newest_data = await fetchSpecificUsersData(
      PrivateAdminPanelInstance,
      upToDateUserData?.user_id
    )

    setUpToDateUserData(newest_data)
  }

  // helper functions
  const handleBackPressed = () => {
    navigate(NAVIGATION_ROUTES?.USERS)
  }

  const handleSetSelectedTab = (tab) => {
    setSelectedTab(tab)
  }

  return (
    <Layout selectedTab={SIDER_TABS?.USERS}>
      <div className='panel-container'>

        <div
          className='back-button-container'
          onClick={handleBackPressed}
        >
          <ArrowLeft className='back-arrow' />
          <p className='back-text'>
            Back To Users
          </p>
        </div>

        <UserManagerHeader
          selectedTab={selectedTab}
          setSelectedTab={handleSetSelectedTab}
        />

        <div className="body-container">

          <EditWalletPopup
            isVisible={isEditWalletPopupVisible}
            onClosePressed={() => setIsEditWalletPopupVisible(false)}
            userData={upToDateUserData}
            refreshUserData={refreshUserData}
          />

          <SuspendUserPopUp
            isVisible={isSuspendUserPopupVisible}
            onClosePressed={() => setIsSuspendUserPopupVisible(false)}
            userData={upToDateUserData}
            refreshUserData={refreshUserData}
          />

          <ToggleACHPopup
            isVisible={isToggleACHPopupVisible}
            onClosePressed={() => setIsToggleACHPopupVisible(false)}
            userData={upToDateUserData}
            refreshUserData={refreshUserData}
          />

          <EditUserDataPopup
            isVisible={isEditUserDataPopupVisible}
            onClosePressed={() => setIsEditUserDataPopupVisible(false)}
            userData={upToDateUserData}
            refreshUserData={refreshUserData}
          />


          <PersonalLimitsPopup
            isVisible={isPersonalLimitsPopupVisible}
            userId={upToDateUserData?.user_id}
            onClosePressed={() => setIsPersonalLimitsPopupVisible(false)}
          />
          
          <AvailablePromotionsPopup
            isVisible={isAvailablePromotionsPopupVisible}
            userId={upToDateUserData?.user_id}
            onClosePressed={() => setIsAvailablePromotionsPopupVisible(false)}
          />

          <PaymentInstrumentsPopup
            isVisible={isPaymentInstrumentsPopupVisible}
            onClosePressed={() => setIsPaymentInstrumentsPopupVisible(false)}
            userData={upToDateUserData}
          />

          <FreeBetsPopup
            isVisible={isFreeBetPopupVisible}
            onClosePressed={() => setIsFreeBetPopupVisible(false)}
            userData={upToDateUserData}
          />

          <ProfitBoostPopup
            isVisible={isProfitBoostPopupVisible}
            onClosePressed={() => setIsProfitBoostPopupVisible(false)}
            userData={upToDateUserData}
          />

          <DeleteUserPopup
            isVisible={isDeleteUserPopupVisible}
            onClosePressed={() => setIsDeleteUserPopupVisible(false)}
            userData={upToDateUserData}
            refreshUserData={refreshUserData}
          />

          {
            selectedTab === USER_HEADER_TABS?.OVERVIEW
              ? (
                <>
                  <UserData
                    data={upToDateUserData}
                  />

                  <UserActions
                    userData={upToDateUserData}
                    onManageMoneyPressed={() => setIsEditWalletPopupVisible(true)}
                    onSuspendUserPressed={() => setIsSuspendUserPopupVisible(true)}
                    onToggleACHPressed={() => setIsToggleACHPopupVisible(true)}
                    onEditUserDataPressed={() => setIsEditUserDataPopupVisible(true)}
                    onEditPersonalLimitsPressed={() => setIsPersonalLimitsPopupVisible(true)}
                    onEditFreeBetPressed={() => setIsFreeBetPopupVisible(true)}
                    onEditProfitBoostPressed={() => setIsProfitBoostPopupVisible(true)}
                    onPaymentInstrumentsPressed={() => setIsPaymentInstrumentsPopupVisible(true)}
                    onEditAvailablePromotionsPressed={() => setIsAvailablePromotionsPopupVisible(true)}
                    onDeleteUserPressed={() => setIsDeleteUserPopupVisible(true)}
                  />

                  <UserStatuses
                    data={upToDateUserData}
                  />
                </>
              )
              : selectedTab === USER_HEADER_TABS?.BETS
                ? (
                  <BetTable
                    userData={upToDateUserData}
                  />
                )
                : selectedTab === USER_HEADER_TABS?.TRANSACTIONS
                ? ( 
                <div className='wallet-container'>
                  <TransactionTable userData={upToDateUserData} />
                  <DepositAttempts showSearchInput={false} username={user_data?.username} />
                </div>
                ) : (
                  <AltOddsTable user_id={upToDateUserData?.user_id} />
                )

          }


        </div>

      </div>
    </Layout>
  );
};

export default UserManager;

