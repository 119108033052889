// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { X } from 'react-feather';

// internal rebet imports
import './stylesEditBetPopup.css'
import createPrivateAdminPanelInstance from '../../../../../api/PrivateAdminPanelInstance';
import { handleExpiredTokenError } from '../../../../../utils/ErrorUtils';
import { API_ROUTES } from '../../../../../api/ApiRoutes';
import { BET_TYPES, BET_STATUS } from '../../../../../utils/Constants';

// component imports
import RebetLoader from '../../../../CommonComponents/RebetLoader/RebetLoader';
import DropDown from '../../../../CommonComponents/DropDown/DropDown';

// asset imports

const SETTLEMENT_OPTIONS = {
  WIN: "WIN", 
  LOSS: "LOSS", 
  CANCEL: "CANCEL"
}


const EditBetPopup = ({
  isVisible, 
  onClosePressed, 
  betData,
  refreshBets
}) => {

  // non-state variables
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken)
  const navigate = useNavigate()

  // state variables
  const [betDataOfInterest, setBetDataOfInterest] = useState({})
  const [betType, setBetType] = useState()
  const [isApiRunning, setIsApiRunning] = useState(false)
  const [lastApiCallError, setLastApiCallError] = useState(false)
  const [lastApiErrorMessage, setLastApiErrorMessage] = useState("")
  const [lastApiCallSuccess, setLastApiCallSuccess] = useState(false)
  const [lastApiCallSuccessMessage, setLastApiCallSuccessMessage] = useState("")
  const [betWinStatus, setBetWinStatus] = useState("")
  const [settlementSelection, setSettlementSelection] = useState(null)
  
  // use effects
  useEffect(() => {
    setBetDataOfInterest(betData)
  }, [betData])

  useEffect(() => {
    if (betDataOfInterest?.cancelled) {
      setBetWinStatus(BET_STATUS?.CANCELLED)
    }
    else if (betDataOfInterest?.void_factor === "1") {
      setBetWinStatus(BET_STATUS?.PUSHED)
    }
    else if (betDataOfInterest?.won === "-1") {
      setBetWinStatus(BET_STATUS?.UNSETTLED)
    }
    else if (betDataOfInterest?.won === "0") {
      setBetWinStatus(BET_STATUS?.LOST)
    }
    else {
      setBetWinStatus(BET_STATUS?.WON)
    }
  }, [betDataOfInterest])

  useEffect(() => {
    if (betDataOfInterest?.bet_type === "1") {
      setBetType(BET_TYPES?.STRAIGHT)
    } 
    else if(betDataOfInterest?.bet_type === "2") {
      setBetType(BET_TYPES?.P2P)
    }
    else if ((betDataOfInterest?.bet_type === "5" && betDataOfInterest?.wager_amount === "0")
      || betDataOfInterest?.is_multibet) {
      setBetType(BET_TYPES?.GROUP)
    }
    else {
      setBetType(BET_TYPES?.PARLAY)
    }
  }, [betDataOfInterest])

  // api functions
  const settleBet = async () => {
    setIsApiRunning(true)

    setLastApiCallError(false)
    setLastApiErrorMessage("")
    setLastApiCallSuccess(false)
    setLastApiCallSuccessMessage("")

    const params = {
      bet_id: betDataOfInterest?.is_multibet ? betDataOfInterest?.original_bet_id : betDataOfInterest?.bet_id, 
      status: (
        settlementSelection === SETTLEMENT_OPTIONS?.WIN
        ? 1
        : settlementSelection === SETTLEMENT_OPTIONS?.LOSS
        ? 2
        : 3
      ),
      ...(betDataOfInterest?.is_multibet !== undefined && { bet_index: betDataOfInterest.group_bet_index })

    }

    const api_route = (
      betType === BET_TYPES?.STRAIGHT
      ? API_ROUTES?.EDIT_SINGLE_BET
      : betType === BET_TYPES?.PARLAY
      ? API_ROUTES?.EDIT_PARLAY_BET
      : betType === BET_TYPES?.GROUP
      ? API_ROUTES?.EDIT_GROUP_BET
      : null
    )

    try {

      const response = await PrivateAdminPanelInstance?.post(api_route, params)

      getNewBetData()
      refreshBets()

      setLastApiCallSuccess(true)
      setLastApiCallSuccessMessage(`${betData?.bet_id} edited successfully!`)
      setIsApiRunning(false)

      setTimeout(() => {
        setLastApiCallSuccess("")
        setLastApiCallSuccessMessage("")
        onClosePressed()
      }, 2000)

    } catch (error) {
      console.log("Error settling the users bet", error?.response)
      setIsApiRunning(false)
      setLastApiCallError(true)
      setLastApiErrorMessage(error?.response?.data?.message)
      handleExpiredTokenError(navigate, error?.response)
    }

  }

  const getNewBetData = async () => {
    const params = {
      bet_id: betData?.is_multibet ? betData?.original_bet_id : betData?.bet_id, 
      bet_type: betData?.bet_type
    }

    try {

      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_BET_BY_ID, params)

      setBetDataOfInterest(response?.data?.data)

    } catch (error) {
      console.log("error fetching new bet details", error?.response)
    }
  }

  // helper functions
  const handleClosePressed = () => {
    onClosePressed()
  }

  const handleSetSettlementSelection = (value) => {
    setSettlementSelection(value)
  }
  
  const handleEditBetPressed = () => {
    console.log("PRESSED")
    settleBet()
  }
 
  if(!isVisible) return null;
  return (
    <div className="edit-bet-popup">

      <div className='edit-bet-panel'>

      <div className='edit-bet-header'>

        <div className='edit-bet-header-label'>
          Edit Bet
        </div>

        <button 
        className='close-button'
        onClick={handleClosePressed}
        >
          <X 
          className='close-icon'
          />
        </button>
      </div>

      <div className='current-bet-status-label'>
        Bet Id: 
      </div>

      <div className='current-bet-status-value'>
        {betDataOfInterest?.bet_id}
      </div>

      <div className='current-bet-status-label'>
        Settlement Status: 
      </div>

      <div className={

              betWinStatus === BET_STATUS?.WON
            ? "win-type-text-background-good"
            : betWinStatus === BET_STATUS?.PUSHED || betWinStatus === BET_STATUS?.CANCELLED
            ? "win-type-text-background-neutral"
            : betWinStatus === BET_STATUS?.LOST
            ? "win-type-text-background-negative"
            : betWinStatus === BET_STATUS?.UNSETTLED
            ? "value-text-background-unsettled"
            : ""
      }
          >
            <div className={
            
            betWinStatus === BET_STATUS?.WON
            ? "win-type-text-good"
            : betWinStatus === BET_STATUS?.PUSHED || betWinStatus === BET_STATUS?.CANCELLED
            ? "win-type-text-neutral"
            : betWinStatus === BET_STATUS?.LOST
            ? "win-type-text-negative"
            : betWinStatus === BET_STATUS?.UNSETTLED
            ? "value-text-unsettled"
            : "bet-cell-label"
          
            }
          >
            {betWinStatus}
            </div>
        </div>

        <div className='current-bet-status-label'>
        Change To: 
        </div>

        <DropDown 
        drop_down_options={SETTLEMENT_OPTIONS}
        selected_value={settlementSelection}
        set_selected_value={handleSetSettlementSelection}
        />

      <div className='suspend-button-container'>
          <button 
            className={
              'submit-button'
            }
            onClick={handleEditBetPressed}
          >
            {
              isApiRunning
              ? (
                <RebetLoader 
                height={'50px'}
                width={'50px'}
                />
              )
              : "Edit Bet"
            }
          </button>
      </div>


      {
        lastApiCallError 
        ? (
          <div className='error-message'>
            {lastApiErrorMessage}
          </div>
        ) 
        : null
      }

    {
        lastApiCallSuccess 
        ? (
          <div className='success-message'>
            {lastApiCallSuccessMessage}
          </div>
        ) 
        : null
      }

      </div>
      
    </div>
  );
};

export default EditBetPopup;

