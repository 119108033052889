// PaymentsRow.jsx
import React from "react";
import "./stylesPaymentsRow.css";
import DeleteIcon from "../../../assets/icons/ic_delete_red.png";
// import DeleteIcon from "../../../../../../../../assets/icons/ic_delete_red.png";

function PaymentsRow({
  is_header_row,
  data,
  setIsDeleteRowPopupVisible,
  setSelectedRow,
}) {
  const handleDeleteRow = () => {
    setSelectedRow(data);
    setIsDeleteRowPopupVisible(true);
  };

  return (
    <div className={`table-row ${is_header_row ? "table-header-row" : ""}`}>
      <div className="cell-small">
        <p className={`cell-label ${is_header_row ? "" : "extra-small"}`}>
          {is_header_row ? "ID" : data?.user_id}
        </p>
      </div>
      <div className="cell-small">
        <p className="cell-label">{is_header_row ? "Name" : data?.username}</p>
      </div>
      <div className="cell-small">
        <p className="cell-label">
          {is_header_row ? "Amount ($)" : data?.amount}
        </p>
      </div>
      <div className="cell-small">
        <p className="cell-label">
          {is_header_row ? (
            "Action"
          ) : (
            <img
              className="delete-icon"
              src={DeleteIcon}
              alt="delete icon"
              onClick={handleDeleteRow}
            />
          )}
        </p>
      </div>
    </div>
  );
}

export default PaymentsRow;
