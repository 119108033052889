import React, { useState } from "react";
import createPrivateAdminPanelInstance from "../../../api/PrivateAdminPanelInstance";
import { API_ROUTES } from "../../../api/ApiRoutes";

import "./stylesDeleteRowPopup.css";
import { X } from "react-feather";
import CheckBox from "../../CommonComponents/CheckBox/CheckBox";

function DeleteRowPopup({
  isVisible,
  onClosePressed,
  getRedemptionsPending,
  selectedRow,
}) {
  const token = JSON.parse(localStorage.getItem("token"));

  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(
    token?.IdToken
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const buttonDisabled = !isCheckBoxChecked;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (buttonDisabled || !selectedRow) {
      return;
    }

    setIsSubmitting(true);

    const params = {
      user_id: selectedRow.user_id,
      transaction_id: selectedRow.transaction_id,
    };

    try {
      await PrivateAdminPanelInstance?.post(API_ROUTES?.DELETE_SINGLE_PENDING_REDEMPTION, params);

      setSuccessMessage("Transaction deleted successfully.");

      await getRedemptionsPending();
      onClosePressed();
    } catch (error) {
      setErrorMessage(error?.response?.data?.message || "Error deleting transaction.");
      console.log("Error adding event code:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClosePressed = () => {
    onClosePressed();
    setIsCheckBoxChecked(false);
    setIsSubmitting(false);
    setSuccessMessage("");
    setErrorMessage("");
  };

  const handleCheckBoxPressed = () => {
    setIsCheckBoxChecked(!isCheckBoxChecked);
  };

  if (!isVisible) return null;
  return (
    <div className="delete-row-popup">
      <div className="delete-row-panel">
        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <div className="delete-row-panel-header">
          <p>Delete {selectedRow.username}'s transaction</p>
          <button className="close-button" onClick={handleClosePressed}>
            <X className="close-icon" />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="checkbox-container">
            <CheckBox
              className="delete-check-box"
              onClicked={handleCheckBoxPressed}
              isSelected={isCheckBoxChecked}
              width={"30px"}
              height={"30px"}
              border_radius={"8px"}
            />

            <div className="delete-check-text">
              I understand that this is permanent.
            </div>
          </div>

          <button
            type="submit"
            disabled={buttonDisabled}
            className={`submit-button ${
              buttonDisabled || isSubmitting ? "disabled" : null
            }`}
          >
            {isSubmitting ? "Deleting..." : "Delete"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default DeleteRowPopup;
