export const NAVIGATION_ROUTES = {
    LOGIN: '/',
    USERS: '/users/home',
    USER_MANAGER: '/users/manager',
    SPORTSBOOK: '/sportsbook',
    KPIS: '/kpis',
    AFFILIATES: '/affiliates',
    ADMIN: '/admin',
    EVENTS: '/events',
    STAFF_MANAGEMENT: '/staff-management',
    PAYMENTS: '/payments',
    PROMOTIONS: '/promotions',
}