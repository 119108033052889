// third party imports
import React, { useState, useEffect } from "react";

// internal rebet imports
import "./stylesPayments.css";
import { SIDER_TABS } from "../../utils/Constants";
import Layout from "../CommonComponents/Layout/Layout";

// component imports
import RebetLoader from "../CommonComponents/RebetLoader/RebetLoader";
import PaymentsRow from "./PaymentsRow/PaymentsRow";
import createPrivateAdminPanelInstance from "../../api/PrivateAdminPanelInstance";
import { API_ROUTES } from "../../api/ApiRoutes";
import PaymentsInfo from "./PaymentsInfo/PaymentsInfo";
import DeleteRowPopup from "./DeleteRowPopup/DeleteRowPopup";
import { formatNumber } from "chart.js/helpers";

// asset imports

const Payments = () => {
  // non-state variables
  const token = JSON.parse(localStorage.getItem("token"));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(
    token?.IdToken
  );

  // state variables
  const [isApiRunning, setIsApiRunning] = useState(true);
  const [paymentsTable, setPaymentsTable] = useState([]);
  const [isDeleteRowPopupVisible, setIsDeleteRowPopupVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState({
    usersAffected: 0,
    totalAmount: 0,
  });

  // use effects

  // api functions
  const getRedemptionsPending = async () => {
    setIsApiRunning(true);
    try {
      const requests = [
        PrivateAdminPanelInstance?.post(API_ROUTES?.REDEMPTIONS_PENDING),
        PrivateAdminPanelInstance?.post(
          API_ROUTES?.TOTAL_PENDING_REDEMPTIONS_AMOUNT
        ),
      ];

      const responses = await Promise.allSettled(requests);

      const [redemptionsPending, totalPendingRedemptionsAmount] = responses;

      if (redemptionsPending.status === "fulfilled") {
        const redemptionsPendingData = redemptionsPending?.value?.data?.data;
        setPaymentsTable(redemptionsPendingData);
      }

      if (totalPendingRedemptionsAmount.status === "fulfilled") {
        const totalPendingRedemptionsAmountData =
          totalPendingRedemptionsAmount?.value?.data?.data;
        setPaymentInfo({
          usersAffected: formatNumber(
            totalPendingRedemptionsAmountData?.total_count
          ),
          totalAmount: formatNumber(
            totalPendingRedemptionsAmountData?.total_amount
          ),
        });
      }
    } catch (error) {
      console.log("error getting admins", error);
    } finally {
      setIsApiRunning(false);
    }
  };

  useEffect(() => {
    getRedemptionsPending();
  }, []);

  // helper functions

  return (
    <Layout selectedTab={SIDER_TABS?.PAYMENTS}>
      <div className="payments-body-container">
        <DeleteRowPopup
          isVisible={isDeleteRowPopupVisible}
          onClosePressed={setIsDeleteRowPopupVisible}
          getRedemptionsPending={getRedemptionsPending}
          selectedRow={selectedRow}
        />
        <div className="payments-table">
          <>
            <div className="head-controls">
              <div className="header-text-container">
                <p className="header-text">Finix Delayed Payment</p>
              </div>
            </div>

            <div className="payments-content-container">
              {isApiRunning ? (
                <RebetLoader height={"100px"} width={"100px"} />
              ) : (
                <>
                  <div className="table-container">
                    <PaymentsRow is_header_row={true} code={{}} />

                    {paymentsTable &&
                      paymentsTable.map((user, index) => (
                        <PaymentsRow
                          key={index}
                          is_header_row={false}
                          data={user}
                          setIsDeleteRowPopupVisible={
                            setIsDeleteRowPopupVisible
                          }
                          setSelectedRow={setSelectedRow}
                        />
                      ))}
                  </div>
                  <PaymentsInfo
                    getRedemptionsPending={getRedemptionsPending}
                    paymentInfo={paymentInfo}
                    apiRunning={isApiRunning}
                  />
                </>
              )}
            </div>
          </>
        </div>
      </div>
    </Layout>
  );
};

export default Payments;
