import React, { useEffect, useState } from "react";
import createPrivateAdminPanelInstance from "../../../api/PrivateAdminPanelInstance";
import { API_ROUTES } from "../../../api/ApiRoutes";
import RebetLoader from "../../CommonComponents/RebetLoader/RebetLoader";
import SaveButton from "../../CommonComponents/SaveButton/SaveButton";

function PaymentsInfo({ getRedemptionsPending, paymentInfo, apiRunning }) {
  const token = JSON.parse(localStorage.getItem("token"));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(
    token?.IdToken
  );

  const [isApiRunning, setIsApiRunning] = useState(apiRunning);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");


  const payoutUsers = async () => {
    setIsApiRunning(true);
    setErrorMessage("");

    try {
      const response = await PrivateAdminPanelInstance?.post(
        API_ROUTES?.PENDING_REDEMPTIONS_TRIGGER
      );

      setIsApiRunning(false);
      setSuccessMessage("Payout successful.");

      setTimeout(async () => {
        setSuccessMessage("");
        await getRedemptionsPending();
      }, 3000);

    } catch (error) {
      console.log("error getting admins", error);
      setErrorMessage("Payout failed.");
    } finally {
      setIsApiRunning(false);
    }
  };

  return (
    <div className="payments-info">
      {isApiRunning ? (
        <RebetLoader height={"100px"} width={"100px"} />
      ) : (
        <>
          {successMessage && (
            <div className="success-message">{successMessage}</div>
          )}
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <div className="stat">
            <div className="stat-title">Users affected</div>
            <div className="stat-value">
              {paymentInfo.usersAffected}{" "}
              {paymentInfo.usersAffected >= 1 ? "user" : "users"}
            </div>
          </div>
          <div className="stat">
            <div className="stat-title">Total amount</div>
            <div className="stat-value">$ {paymentInfo.totalAmount}</div>
          </div>
          <SaveButton
            isApiRunning={isApiRunning}
            callbackFunction={payoutUsers}
            buttonText="Payout users"
          />
        </>
      )}
    </div>
  );
}

export default PaymentsInfo;
