import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from 'react-auth-kit';
import { NAVIGATION_ROUTES } from './utils/NavigationUtils';
import RequireAuth from './RequireAuth';

// component imports 
import MobileSplashScreen from './components/MobileSplashScreen/MobileSplashScreen';
import LogIn from './components/LogIn/LogIn';
import AllUsers from './components/Users/AllUsers/AllUsers'
import UserManager from './components/Users/UserManager/UserManager';
import Sportsbook from './components/Sportsbook/Sportsbook';
import KPIs from './components/KPIs/KPIs';
import AdminManagement from './components/AdminManagement/AdminManagement';
import Affiliates from './components/Affiliates/Affiliates';
import Events from './components/Events/Events';
import Promotions from './components/Promotions/Promotions';
import WhiteList from './components/WhiteList/WhiteList';

import './App.css'
import './fonts.css'
import './table.css'
import '@fontsource/sora/600.css'; // 600 is the weight for Semibold
import Payments from './components/Payments/Payments';

const App = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 650); // Example width for mobile devices
    };

    handleResize(); // Check the initial screen size
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={isMobile ? 'mobile-app-container' : 'app-container'}>
      {isMobile ? (
        <MobileSplashScreen />
      ) : (
        <AuthProvider authType={'cookie'}
                      authName={'_auth'}
                      cookieDomain={window.location.hostname}
                      cookieSecure={false}>
          <BrowserRouter>
            {/* Each route is given separation to allow for visual consistency and protected routes */}
            <Routes>
              {/* Unprotected routes */}
              <Route path={NAVIGATION_ROUTES?.LOGIN} element={<LogIn />} />

              {/* Protected routes */}
              <Route path={NAVIGATION_ROUTES?.USERS} element={
                <RequireAuth>
                  <AllUsers />
                </RequireAuth>
              } />
              <Route path={NAVIGATION_ROUTES?.USER_MANAGER} element={
                <RequireAuth>
                  <UserManager />
                </RequireAuth>
              } />
              <Route path={NAVIGATION_ROUTES?.SPORTSBOOK} element={
                <RequireAuth>
                  <Sportsbook />
                </RequireAuth>
              } />
              <Route path={NAVIGATION_ROUTES?.KPIS} element={
                <RequireAuth>
                  <KPIs />
                </RequireAuth>
              } />
               <Route path={NAVIGATION_ROUTES?.AFFILIATES} element={
                <RequireAuth>
                  <Affiliates />
                </RequireAuth>
              } />
              <Route path={NAVIGATION_ROUTES?.ADMIN} element={
                <RequireAuth>
                  <AdminManagement />
                </RequireAuth>
              } />
              <Route path={NAVIGATION_ROUTES?.EVENTS} element={
                <RequireAuth>
                  <Events />
                </RequireAuth>
              } />
              <Route path={NAVIGATION_ROUTES?.STAFF_MANAGEMENT} element={
                <RequireAuth>
                  <WhiteList />
                </RequireAuth>
              } />
              <Route path={NAVIGATION_ROUTES?.PAYMENTS} element={
                <RequireAuth>
                  <Payments />
                </RequireAuth>
              } />
              <Route path={NAVIGATION_ROUTES?.PROMOTIONS} element={
                <RequireAuth>
                  <Promotions />
                </RequireAuth>
              } />

            </Routes>
          </BrowserRouter>
        </AuthProvider>
      )}
    </div>
  );
};

export default App;
