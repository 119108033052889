import React from "react";
import RebetLoader from "../RebetLoader/RebetLoader";

import "./stylesSaveButton.css";

function SaveButton({ isApiRunning, callbackFunction, buttonText = "Save" }) {
  return (
    <button
      className={`save-button ${isApiRunning ? "saving" : ""}`}
      disabled={isApiRunning}
      onClick={callbackFunction}
    >
      {isApiRunning ? <RebetLoader height={"20px"} width={"20px"} /> : buttonText}
    </button>
  );
}

export default SaveButton;
