export const API_ROUTES = {

    // public routes
    LOG_IN: '/onboarding/sign-in',
    GET_PERMISSIONS: '/get-admin-permission',

    // user routes
    GET_USERS: '/all-users',
    SEARCH_USER: '/search-user',
    GET_USER_BETS: '/get-user-bets',
    GET_BET_BY_ID: '/retrieve-bet-details',
    EDIT_USER_PROFILE: '/edit-user-profile',
    EDIT_USER_WALLET: '/update-user-wallet',
    DELETE_USER: '/delete-user',
    CHANGE_USER_ACH: '/update-user-ach-status',
    SUSPEND_UNSUSPEND_USER: '/suspend-unsuspend-user',
    GET_TRANSACTION_HISTORY: '/get-user-transaction-history',
    GET_PAYMENT_INSTRUMENTS: '/fetch-user-bank-accounts',
    DELETE_ALL_PAYMENT_INSTRUMENTS: '/delete-user-bank-accounts',
    DELETE_SPECIFIC_PAYMENT_INSTRUMENT: '/delete-bank-from-bank-list',
    SEND_EMAIL_VERIFICATION: '/send-verification-code-to-email',
    EDIT_SINGLE_BET: '/settle-sportbook-bet',
    EDIT_PARLAY_BET: '/settle-parlay-bet',
    EDIT_GROUP_BET: '/settle-group-bet',
    GET_EVENT_CODES_LIST: '/get-event-codes-list',
    UPDATE_EVENT_CODE: '/update-event-code',
    GET_PLAID_STATUS_BY_DAY: '/get-plaid-status-by-day',
    ADD_EVENT_CODE: '/add-event-code',
    GET_USERS_TOU_STATUS: '/get-users-tou-status',
    USERS_DEPOSIT_ATTEMPTS: '/users-deposit-attempts',
    USERS_PERSONAL_LIMIT: '/users-personal-limit',
    UPDATE_USER_LOCKED_LIMIT: '/update-user-locked-limit',
    SET_USERS_PERSONAL_LIMIT: '/set-users-personal-limit',
    GIVE_USER_FREE_PICK: '/give-user-free-pick',
    GIVE_USER_PROFIT_BOOST: '/give-user-profit-boost',
    GET_ALT_ODDS: '/get-alt-odds',
    GET_ALT_ODDS_HISTORY: '/get-alt-odds-history',

    // kpi routes
    GET_SPORTRADAR_BET_METRICS: '/get-betting-metrics',
    REFRESH_SPORTRADAR_BET_METRICS: '/refresh-betting-metrics',
    GET_TOTAL_NUM_USERS: '/get-total-users',
    GET_TOTAL_EMAIL_VERIFIED: '/get-email-status',
    GET_TOTAL_PLAID_VERIFIED: '/get-plaid-status',
    GET_TOTAL_PURCHASES: '/get-total-purchases',
    GET_TOTAL_PRIZE_REDEMPTIONS: '/get-total-redemptions',
    GET_TOTAL_REVENUE: '/get-total-revenue',
    GET_TOTAL_REDEEMABLE_AMOUT: '/get-total-redeemable-amount',
    GET_TRANSACTIONS_BY_STATE: '/get-transactions-by-state',
    REFRESH_TRANSACTIONS_BY_STATE: '/refresh-transactions-by-state ',
    GET_EMAIL_STATUS_BY_DAY: '/get-email-status-by-day',
    GET_FREE_CASH_METRICS: '/get-free-cash-metrics',
    GET_PROFIT_BOOST_METRICS: '/get-profit-boost-metrics',
    GET_APPLE_PAY_PROVIDER_SETTINGS: '/get-apple-pay-provider-settings',
    UPDATE_APPLE_PAY_PROVIDER_SETTINGS: '/update-apple-pay-provider-setting',

    //Affiliate routes
    REFRESH_ADMIN_AFFILIATE_DATA: '/refresh-admin-affiliate-data',
    GET_ALL_REFERRAL_CODES: '/get-all-referral-codes',
    GET_FTD_LIST: '/get-ftd-list',
    GET_FTD_LIST_BY_DAY: '/get-ftd-list-by-day',
    TOTAL_YEAR_COMMISSION_PAIDOUT: '/total-year-commission-paidout',
    GET_AVG_CAC: '/get-avg-cac',
    ADMINS_TOTAL_COMMISSION_MONTHLY: '/admins-total-commission-month',
    GET_TOTAL_FTDS: '/get-total-ftds',
    GET_TOTAL_MONTHLY_FTDS: '/get-total-monthly-ftds',
    GET_TOTAL_MONTHLY_DEPOSIT_RATIO: '/total-monthly-deposit-ratio',
    GET_REFERRAL_STATS: '/get-referral-stats',
    ADMINS_TOTAL_REVENUE: '/admins-total-revenue',
    TOP_PERFORMERS_CURRENT_MONTH: '/top-performers-current-month',
    AVG_MONTHLY_LTV: '/avg-monthly-ltv',
    TOTAL_SIGNUPS_YEAR: '/total-signups-year',
    TOTAL_MONTHLY_SIGNUPS: '/total-monthly-signups',
    TOTAL_DEPOSIT_RATIO: '/total-deposit-ratio',
    AVG_MONTHLY_REVENUE: '/avg-monthly-revenue',
    ADMINS_TOTAL_COMMISSION_MONTH_BREAKDOWN: 'admins-total-commission-month-breakdown',

    // admin routes
    CREATE_ADMIN: '/create-admin-user',
    EDIT_ADMIN: '/update-admin-group',
    GET_ADMINS: '/get-admins',

    // whitelist routes
    WHITELIST_IP: 'whitelist-ip',

    // promotions routes
    FETCH_INITIAL_DEPOSIT_AND_REFERRER_VALUES: '/fetch-initial-deposit-and-referrer-values',
    UPDATE_INITIAL_DEPOSIT_AND_REFERRER_VALUES: '/update-initial-deposit-and-referrer-values',
    GET_USER_ACTIVE_BONUSES: '/get-user-active-bonuses',
    EXPIRE_USER_PROMOTION: '/expire-user-promotion',

    // payments routes
    REDEMPTIONS_PENDING: '/redemptions-pending',
    TOTAL_PENDING_REDEMPTIONS_AMOUNT: '/total-pending-redemptions-amount',
    PENDING_REDEMPTIONS_TRIGGER: '/pending-redemptions-trigger',
    DELETE_SINGLE_PENDING_REDEMPTION: '/delete-single-pending-redemption'
}

export const SLASHLESS_API_ROUTES = {

    // public routes
    LOG_IN: 'onboarding/sign-in',
    GET_PERMISSIONS: 'get-admin-permission',

    // user routes
    GET_USERS: 'all-users',
    SEARCH_USER: 'search-user',
    GET_USER_BETS: 'get-user-bets',
    GET_BET_BY_ID: 'retrieve-bet-details',
    EDIT_USER_PROFILE: 'edit-user-profile',
    EDIT_USER_WALLET: 'update-user-wallet',
    DELETE_USER: 'delete-user',
    CHANGE_USER_ACH: 'update-user-ach-status',
    SUSPEND_UNSUSPEND_USER: 'suspend-unsuspend-user',
    GET_TRANSACTION_HISTORY: 'get-user-transaction-history',
    GET_PAYMENT_INSTRUMENTS: 'fetch-user-bank-accounts',
    DELETE_ALL_PAYMENT_INSTRUMENTS: 'delete-user-bank-accounts',
    DELETE_SPECIFIC_PAYMENT_INSTRUMENT: 'delete-bank-from-bank-list',
    SEND_EMAIL_VERIFICATION: 'send-verification-code-to-email',
    EDIT_SINGLE_BET: 'settle-sportbook-bet',
    EDIT_PARLAY_BET: 'settle-parlay-bet',

    // kpi routes
    GET_SPORTRADAR_BET_METRICS: 'get-betting-metrics',
    REFRESH_SPORTRADAR_BET_METRICS: 'refresh-betting-metrics',
    GET_TOTAL_NUM_USERS: 'get-total-users',
    GET_TOTAL_EMAIL_VERIFIED: 'get-email-status',
    GET_TOTAL_PLAID_VERIFIED: 'get-plaid-status',

    // admin routes
    CREATE_ADMIN: 'create-admin-user',
    EDIT_ADMIN: 'update-admin-permission',
    GET_ADMINS: 'get-admins',
}